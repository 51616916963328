const FETCH_START_AUTH = 'FETCH_START_AUTH'
const FETCH_SUCCESS_AUTH = 'FETCH_SUCCESS_AUTH'
const FETCH_ERROR_AUTH = 'FETCH_ERROR_AUTH'

export const fetchStartAuth = () => ({
    type: FETCH_START_AUTH,
})

export const fetchSuccessAuth = payload => ({
    type: FETCH_SUCCESS_AUTH,
    payload
})

export const fetchErrorAuth = payload => ({
    type: FETCH_ERROR_AUTH,
    payload
})

export const initialState = {
    accessToken: null,
    error: null,
    loading: false,
    user: null,
}

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_START_AUTH:
            return {...state, loading: true}
        case FETCH_SUCCESS_AUTH:
            const data = action.payload
            const auxUser = {
                name: `${data.firstname}`,
                lastname: data.lastname,
                rut: data.rut,
                dv: data.dv,
                email: data.email

            }
            const { accessToken } = action.payload
            return {...state, user: auxUser, accessToken}
        case FETCH_ERROR_AUTH:
            return {...state, loading: false, error: action.payload}
        default:
            return state
    }
}