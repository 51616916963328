import React, { createContext, useReducer } from 'react'
import axios from 'axios'
import { initialState, authReducer, fetchStartAuth, fetchSuccessAuth, fetchErrorAuth } from '../../ducks/session'
import Swal from 'sweetalert2'
import { baseApi } from '../../env'

const ContextAuth = createContext({
    authenticated: false,
    user: null,
    accessToken: null,
    initiateLogin: () => {},
    handleAuthentication: () => {},
    logout: () => {}
})

const Auth = props => {
    const savedState = localStorage.getItem('user')

    const [state, dispatch] = useReducer(authReducer, savedState ? JSON.parse(savedState) : initialState)

    const handleAuthentication = async values => {
        dispatch(fetchStartAuth())
        try {
            const response = await axios.post(baseApi+'/user/login', values)
            dispatch(fetchSuccessAuth(response.data))
            setSession(response.data)
        } catch (error) {
            Swal.fire({
                title: 'Error!',
                text: 'Nombre de usuario o contraseña inválido',
                icon: 'error',
                confirmButtonText: 'ok',
                confirmButtonColor: '#073B4C'
              })
            dispatch(fetchErrorAuth(error.response.data.error))
        }
    }

    const setSession = (data, remove = false) => {
        if (remove) return localStorage.clear();
        else localStorage.setItem('user', JSON.stringify(data));
    }

    const logout = () => {
        setSession(null, true)
    }

    const authProviderValue = {
        ...state,
        handleAuthentication,
        logout
    }

    return (
        <ContextAuth.Provider value={authProviderValue}>
            {props.children}
        </ContextAuth.Provider>
    )
}

export {Auth, ContextAuth};