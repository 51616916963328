import mock from '../mock'

let users = [
    {
        id: 1,
        name: 'Juani Perez',
        rut: '13.321.213-3',
        email: 'email@provider.com',
        state: 1,
    },
    {
        id: 2,
        name: 'Marcela Bacareza',
        rut: '13.321.213-3',
        email: 'email@provider.com',
        state: 1,
    },
    {
        id: 3,
        name: 'Desarrollo',
        rut: '13.321.213-3',
        email: 'email@provider.com',
        state: 1,
    },
    {
        id: 4,
        name: 'Desarrollo',
        rut: '13.321.213-3',
        email: 'email@provider.com',
        state: 1,
    },
    {
        id: 5,
        name: 'Desarrollo',
        rut: '13.321.213-3',
        email: 'email@provider.com',
        state: 1,
    },
]

mock.onGet('/api/v1/users').reply(() => {
    return [200, users]
})