import React from 'react'
import * as Icon from 'react-feather'

const navigationConfig = [
    {
        id: "home",
        title: "Registros",
        type: "item",
        icon: <Icon.Home size={20} />,
        navLink: "/dashboard"
    },
    {
        id: "users",
        title: "Usuarios",
        type: "item",
        icon: <Icon.Box size={20} />,
        permissions: ["administrador"],
        navLink: "/dashboard/users"
    },
]

export default navigationConfig